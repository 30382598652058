<template>
    <div class="scopes-item">
        <h6 class="scopes-item__title">
            {{ scope.name }}
        </h6>
        <ui-switch :id="`${scope.name}-active`" :name="`${scope.name}-active`" v-model:value="active" @change="updateStatus" :disabled="disabled" />
    </div>
</template>

<script>

import UiSwitch from '@/components/ui/Switch.vue';

export default {
    name: 'ScopesItem',
    components: { UiSwitch },
    props: {
        scope: {
            type: Object,
            default: null,
            required: true
        },
        preScopes: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits :{
        updateScope: null
    },
    watch: {
        preScopes: {
            handler() {
                if (this.preScopes.includes(this.scope.key)) {
                    this.active = true;
                    this.$emit('updateScope', { scope: this.scope.key, status: true });
                } else {
                    this.active = false;
                    this.$emit('updateScope', { scope: this.scope.key, status: false });
                }
            },
            deep: true
        }
    },
    data() {
        return {
            active: false,
            loading: false,
            url: ''
        };
    },
    methods: {
        updateStatus() {
            if (this.active) {
                this.$emit('updateScope', { scope: this.scope.key, status: true });
            } else {
                this.$emit('updateScope', { scope: this.scope.key, status: false });
            }
        }
    },
};
</script>

<style lang="scss">
.scopes-item {
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    gap: 8px;

    &__title {
        color: $ecart-secondary-500;
        font-weight: 500;
        margin-bottom: 0;
    }
}
</style>