<template>
    <div class="scopes-list">
        <div class="scopes-list__row">
            <div v-for="scope in scopes" :key="scope.name" class="scopes-list__column">
                <h6 class="scopes-list__title">
                    {{ scope.name }}
                </h6>
                <ui-list>
                    <ui-list-item v-for="permission in scope.scopes" :key="permission.key" class="scopes-list__list-item">
                        <scopes-item :scope="permission" @updateScope="handleScopes" :pre-scopes="preSelectedScopes" :disabled="disabled"/>
                    </ui-list-item>
                </ui-list>
            </div>
        </div>
    </div>
</template>

<script>
import UiList from '@/components/ui/list/List.vue';
import UiListItem from '@/components/ui/list/ListItem.vue';
import ScopesItem from '@/components/admins/scopes/ScopesItem.vue';
import scopes from '@/data/scopes';
import { debounce } from 'lodash';

export default {
    name: 'AdminScopes',
    components: { 
        ScopesItem,
        UiList,
        UiListItem,
    },
    data() {
        return {
            scopes: scopes,
            loading: false,
            selectedScopes: [],
        }
    },
    props: {
        preSelectedScopes: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    mounted() {
        this.selectedScopes = this.preSelectedScopes;
    },
    methods: {
        handleScopes(data) {
            let { scope, status } = data;
            if (status) {
                if (!this.selectedScopes.includes(scope)) {
                    this.selectedScopes.push(scope);
                }
            } else {
                this.selectedScopes = this.selectedScopes.filter(item => item !== scope);
            }
            this.emitSelectedScopes();
        },
        emitSelectedScopes: debounce( function() { 
            this.$emit('selectedScopes', this.selectedScopes);
        }, 300)
  }
}
</script>

<style lang="scss" scoped>
.scopes-list {
    &__row {
        display: flex;
        flex-wrap: wrap;
        gap: 40px;
        max-width: 100%;

        > div {
            flex-basis: calc(33.33% - 40px);
        }
    }

    &__title {
        color: $ecart-primary;
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 8px;
    }

    &__list-item {
        border-bottom: 1px solid $ecart-secondary-100;
    }
}
</style>